.resume {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .row {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .infos {
    padding-left: 10px;
    display: flex;
    gap: 10px;
  }
  
  .title {
    font-weight: bold;
  }

  .share{
    display: flex;
    justify-content: end;
    padding: 10px;
    margin: 0 20px;
  }

  .scope{
    display: flex;
    flex-direction: column;
  }
@primary-color: #7c3aa4;