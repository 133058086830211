.diagnosticsChoicesContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.choices {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.explain{
  color: red;
  font-size: large;
}
@primary-color: #7c3aa4;