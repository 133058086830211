.appContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 14%;
}

.navContainer{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px){
  .appContainer {
    padding: 0;
  }
}

.formContainer {
  margin-bottom: 40px;
}

.stepper {
  padding: 20px;
  flex-wrap: wrap;
}

.navButton {
  display: flex;
  padding: 20px;
}


@primary-color: #7c3aa4;