.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
 
  padding: 10px 20px;
  margin: 10px 20px 0;
  background-color: var(--secondary-color-light);
  border-radius: 10px;

  .headerSubContainer {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .contactContainer {
    font-size: 25px;
  }

  a {
    color: #2f54eb;
    text-decoration: inherit;
  }
}

.logo {
  width: 130px;
}

.phoneNumber {
  display: flex;
  gap: 5px;
}

.confirmationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .confirmationIcon {
    font-size: 40px;
    color: greenyellow;
  }
}
@primary-color: #7c3aa4;