.calendar {
  background-color: white;
  height: min-content;
  padding: 5px;
  overflow-y: hidden;
  height: 100vh;
}

.calendarMobile {
  background-color: white;
  height: min-content;
  padding: 5px;
  height: 100vh;
}


.header {
  font-weight: bold;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
}

.fc .fc-timegrid-slot {
  height: 8em;
  cursor: pointer;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center !important;
}

.fc .fc-timegrid-axis-cushion {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .fc-toolbar-title {
    font-size: 18px;
  }

  .fc-button {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
  }

  .fc-daygrid-event,
  .fc-timegrid-event {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }
}
@primary-color: #7c3aa4;