.whatsapp{
    height: 50px;
    width: 50px;
}

.share{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.share_text{
    font-size: large;
    font-weight: bold;
}
@primary-color: #7c3aa4;