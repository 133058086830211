:root {
  --primary-color: #2f54eb;
  --primary-color-3: #adc6ff;
  --primary-color-background: #f0f5ff;

  --secondary-color: #3a5ea6;
  --secondary-color-light: #3a5ea610;

  --choices-card-background: #adc6ff77;

  --transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

  --box-shadow-1: 0 1px 3px rgba(183, 235, 143, 0.12), 0 1px 2px rgba(183, 235, 143, 0.24);
  --box-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --box-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --box-shadow-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --box-shadow-5: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  --box-shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

input {
  font-size: x-large !important;
}

.title {
  font-size: 30px;
}

label {
  font-size: 20px !important;

}


.button-select {
  font-size: 1.7em !important;
  height: max-content !important;
}

.button {
  width: 100%;
  font-size: x-large;
  height: 2em;
}

@media screen and (max-width: 768px) {
  .fc-toolbar-title {
    font-size: 18px;
  }

  .fc-button {
    padding: 0.25rem 0.5rem;
    font-size: 14px;
  }

  .fc-daygrid-event,
  .fc-timegrid-event {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }
}