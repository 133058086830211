body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Lato, system-ui;
    background-color: var(--primary-color-background);
    user-select: none;
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: Lato;
    src: url("./assets/fonts/Lato-Regular.ttf");
}

.fc .fc-timegrid-slot {
    height: 8em;
    cursor: pointer;
}

.fc .fc-timegrid-axis-cushion{
    display: none !important;
}