.navigationButtons {
  display: flex;
  justify-content: center;

  .navButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@primary-color: #7c3aa4;