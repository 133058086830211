.choices {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttonsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@primary-color: #7c3aa4;